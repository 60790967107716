<template>
  <div class="e-dialog e-dialog--new">
    <router-link
      :to="{ name: 'dialogs' }"
      class="e-dialog__back"
    >
      <IconChevronLeft />
    </router-link>

    <div class="e-dialog__inner">
      <div class="e-dialog__scroll">
        <NewMessageEditor :edit-message="editMessage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MessageEditor from 'organisms/MessageEditor/MessageEditor'

// Icons
import IconChevronLeft from 'assets/icons/chevron-left.svg'

const NewMessageEditor = {
  name: 'NewMessageEditor',
  extends: MessageEditor,
  methods: {
    closeForm() {
      this.$router.push({ name: 'dialogs' })
    }
  }
}

export default {
  name: 'NewDialog',
  components: {
    IconChevronLeft,
    NewMessageEditor
  },
  computed: {
    ...mapGetters(['editMessage', 'messageEditorOpen'])
  }
}
</script>
