<template>
  <div class="e-messages">
    <VuePerfectScrollbar
      class="e-messages__inner"
      :class="{ 'e-messages__inner--has-new-button': showNewDialogButton }"
    >
      <div
        v-if="dialogsLoading"
        class="e-messages__note e-messages__note--loading"
      >
        <Loading />
        <p>{{ $t('system.messages.loading') }}</p>
      </div>

      <template v-else>
        <MessageListItem
          v-for="message in items"
          :key="message.id"
          :data="message"
        />

        <div class="e-messages__note">
          {{ noMessages }}
        </div>
      </template>
    </VuePerfectScrollbar>

    <div
      v-if="showNewDialogButton"
      class="e-messages__add"
    >
      <el-button
        type="tertiary"
        @click="newDialog"
      >
        <span class="e-btn__text">
          {{ $t('system.messages.new') }}
        </span>
        <IconPlus class="e-mobile-hidden" />
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import Loading from 'atoms/Loading/Loading'
import MessageListItem from 'molecules/MessageListItem/MessageListItem'

// Icons
import IconPlus from 'assets/icons/plus.svg'

export default {
  name: 'MessageList',
  components: {
    IconPlus,
    Loading,
    MessageListItem,
    VuePerfectScrollbar
  },
  props: {
    showNewDialogButton: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters(['dialogsLoading']),
    noMessages() {
      return this.items.length > 0
        ? this.$t('system.messages.noMore')
        : this.$t('system.messages.noItems')
    }
  },
  methods: {
    newDialog() {
      this.$router.push({
        name: 'newDialog'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/messagelist';
</style>
